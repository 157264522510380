<template>
  <v-app>
    <v-row justify="center" class="mb-12">
      <v-col lg="5" sm="12" md="10" v-for="(content, i) in contents" :key="i">
        <v-card
          class="mx-auto elevation-20"
          color="indigo"
          dark
          style="max-width: auto"
        >
          <v-row justify="space-between" lg="12" md="7" sm="7">
            <v-col lg="7" sm="7" md="7">
              <v-card-title primary-title>
                <div>
                  <h4>{{ content.titulo }}</h4>
                  <p style="font-weight:100; font-size:16px">
                    {{ content.texto }}
                  </p>
                </div>
              </v-card-title>
            </v-col>
            <v-img
              v-if="content.picture"
              class="shrink ma-2 mx-0"
              contain
              max-height="115px"
              :src="mountPicture(content.picture)"
              style="flex-basis: 115px"
            />
            <v-img
              v-else
              class="shrink ma-2 mx-0"
              contain
              max-height="115px"
              :src="
                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2lJRGrhR4Yb1jwlq8qf8QUZYqprdu2CrLKQ&usqp=CAU'
              "
              style="flex-basis: 115px"
            />
          </v-row>
          <v-divider dark></v-divider>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
export default {
  props: {
    contents: {
      type: Array,
      required: true
    }
  },
  methods: {
    mountPicture(picture) {
      const prefixBase64 = `data:image/jpeg;base64,`;

      return `${prefixBase64}${picture}`;
    }
  }
};
</script>
