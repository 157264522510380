<template>
  <v-menu
    open-on-hover
    transition="slide-y-transition"
    bottom
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-title v-text="title" style="margin-top:25px" />
      </v-btn>
    </template>
    <v-list dark color="indigo" style="margin-top:35px">
      <v-list-item
        v-for="(category, i) in categories"
        :key="i"
        @click="detail(category.id, category.nome)"
        exact
        color="white"
      >
        <v-list-item-title v-text="category.nome"/>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props: {
    title: {
      type: String
    },
    categories: {
      type: Array
    }
  },
  methods: {
    detail(id, title) {
      this.$router.replace({ 
        name: "products", 
        query: { categoria: id } ,
        params: { title }
      });
    }
  },  
};
</script>