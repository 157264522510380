<template v-slot:default="{ hover }">
  <v-container class="pa-2 text-center fill-height" @click="detail(id, title)">
    <v-row class="fill-height my-4 mx-2" align="center" justify="center">
      <v-hover v-slot:default="{ hover }">
        <v-card
          class="mx-auto"
          max-width="300"
          min-height="450"
          max-height="550"
        >
          <v-img 
            v-if="pictures.length > 0" 
            contain
            :src="localPicture" 
            min-width="300"
            max-height="300" 
            min-height="300"/>
          <v-card-text>
            <h2 class="title primary--text" v-text="title" />
            {{ desc }}
          </v-card-text>
          <v-fade-transition>
            <v-overlay
              v-if="hover"
              absolute
              color="#3f51b5"
              style="z-index:1 !important"
            >
              <!--v-btn @click="detail(id)">Ver detalhes</v-btn-->
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </v-hover>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    id: {
      type: Number
    },
    title: {
      required: true,
      type: String
    },
    desc: {
      required: true,
      type: String
    },
    pictures: {
      required: false,
      type: Array
    }
  },
  data() {
    return {
      overlay: false,
      dialog: false,
      localPicture: null
    };
  },
  created() {
    if (this.pictures.length > 0) {
      this.localPicture = this.mountPicture(this.pictures);
    }
  },
  methods: {
    closeDialog(e) {
      this.dialog = e;
    },
    mountPicture(pictures) {
      return `data:image/jpeg;base64,${pictures[0].bytesarq_base64}`;
    },
    detail(id, title) {
      this.$router.push({ 
        name: "products", 
        query: { categoria: id },
        params: {
          title
        }
      });
    }
  }
};
</script>
<style>
.v-overlay__scrim {
  cursor: pointer;
}
</style>
