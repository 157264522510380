import Repository from "./repository";
const resource = "/Produtos";
export default {
  findAll() {
    return Repository.get(`${resource}/Destaques`);
  },

  findAllByCategory(idCategory) {
    return Repository.get(`/Categorias${resource}?idCategoria=${idCategory}`);
  },

  findOne(idProduct) {
    return Repository.get(`${resource}?idProduto=${idProduct}`);
  },

  findProductDetails(idProduct) {
    return Repository.get(
      `${resource}/Caracteristicas/Especs?idProduto=${idProduct}`
    );
  },

  findProductPicture(idProduct) {
    return Repository.get(`${resource}/Fotos?idProduto=${idProduct}`);
  },
  downloadPDF(idProduct) {
    return Repository.get(`${resource}/DataSheet?idProduto=${idProduct}`)
  }
};
