<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" fixed app color="indigo" dark>
      <v-list>
        <div
          v-for="(item, i) in items"
          :key="i"
        >
          <v-list-item v-if="item.subLinks">
            <nested-menu
              :title="item.title"
              :icon="item.icon"
              :categories="listCategories"
              :key="listCount"
            />
          </v-list-item>
          <v-list-item 
            :to="item.to"
            router
            exact 
            v-else>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      fixed
      app
      color="indigo"
      dark
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title v-text="title" />
      <v-spacer />
    </v-app-bar>
  </v-app>
</template>
<script>
import NestedMenu from "./nested-menu.vue";
import { mapGetters } from 'vuex'

export default {
  components: {
    NestedMenu
  },
  props: {
    items: {
      type: Array
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      drawer: false,
      fixed: false,
      right: true
    };
  },
  computed: {
    ...mapGetters(['listCategories', 'listCount'])
  }
};
</script>
