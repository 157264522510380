import Vue from "vue";
import Vuex from "vuex";
import Bluebird from "bluebird";
import { RepositoryFactory } from "@/repositories/factory";
const CategoriesRepository = RepositoryFactory.get("categories");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    categories: [],
    count: 0
  },
  getters: {
    listCategories: state => state.categories,
    listCount: state => state.count,
  },
  mutations: {
    INSERT_CATEGORIES: (state, categories) => state.categories = categories,
    INSERT_COUNT: (state) => state.count += 1,
    CLEAR_COUNT: (state) => state.count =  0
  },
  actions: {
    insertCategories: ({commit}) => {
      Bluebird.resolve(CategoriesRepository.findAll())
        .then(({ data: categories }) =>{
          commit('INSERT_CATEGORIES', categories)
          commit('CLEAR_COUNT')
        })
    },
    insertCount: ({commit}) => {
      commit('INSERT_COUNT')
    }
  }
});
