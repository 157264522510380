<style scoped>
.v-toolbar {
  font-family: Roboto, sans-serif;
  background-color: #3f51b5 !important;
  color: white;
}
span .v-btn__content {
  color: white !important;
}
.v-btn {
  height: 60px !important;
}
a {
  text-decoration: none !important;
}
</style>
<template>
  <div>
    <v-toolbar dark color="indigo">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items
        v-for="(item, i) in items"
        :key="i"
        color="white"
      >
        <router-link :to="item.to" exact>
          <v-btn text v-if="!item.subLinks">
            <v-list-item-title v-text="item.title" />
          </v-btn>
          <nested-menu
            style="pointer-events: none !important;"
            :title="item.title"
            :categories="listCategories"
            :key="listCount"
            v-else
          />
        </router-link>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>
<script>
import NestedMenu from "./nested-menu.vue";
import { mapGetters } from 'vuex'

export default {
  components: {
    NestedMenu
  },
  props: {
    items: {
      type: Array
    },
    title: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['listCategories', 'listCount'])
  }
};
</script>
