import CategoriesFactory from "./categories";
import ContentsFactory from "./contents";
import ProductsFactory from "./products";

const repositories = {
  products: ProductsFactory,
  categories: CategoriesFactory,
  contents: ContentsFactory
};

export const RepositoryFactory = {
  get: name => repositories[name]
};
