<template>
  <v-app>
    <div
      v-if="loading"
      style="display: flex;align-items: center;justify-content: center;margin: 5em; "
    >
      <v-progress-circular
        :size="70"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <Carrousel
      v-if="!loading"
      :pictures="covers"
      :keyToPicture="'bytesarq_base64'"
    />
    <h1 v-if="!loading" class="mx-auto mt-4">Destaques</h1>
    <div v-if="!loading" class="product-container">
      <div v-for="(category, i) in categories" :key="i">
        <Category
          :id="category.id"
          :title="category.nome"
          :desc="category.descricao"
          :pictures="category.pictures"
        />
      </div>
    </div>
    <h1 v-if="!loading" class="mx-auto mt-0 mb-6">Nossos serviços</h1>
    <Services v-if="!loading" :contents="services" />
    <br /><br /><br /><br /><br />
    <v-footer
      v-if="!loading"
      padless
      class="justify-center align-center mt-20"
      style="display:flex;flex-direction:column!important"
    >
      <v-row class="d-none">
        <v-col cols="6" sm="4" class="pa-lg-8 pa-4">
          <v-img src="../assets/rina-iso.png" max-width="200" />
        </v-col>
        <v-col cols="6" sm="4" class="pa-lg-8 pa-4">
          <v-img src="../assets/anatel.png" max-width="200" />
        </v-col>
        <v-col cols="6" sm="4" class="pa-lg-8 pa-4">
          <v-img src="../assets/iso-9001.png" max-width="200" />
        </v-col>
      </v-row>
      <v-row class="ma-2 pa-2 mb-12">
        <span>Polidesign&copy; - Todos os direitos reservados.</span>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
import Bluebird from "bluebird";
import Category from "@/components/category.vue";
import Carrousel from "@/components/carrousel.vue";
import Services from "@/components/services.vue";
import { RepositoryFactory } from "@/repositories/factory";

const ContentRepository = RepositoryFactory.get("contents");
const CategoriesRepository = RepositoryFactory.get("categories");

export default {
  name: "App",
  components: {
    Carrousel,
    Services,
    Category
  },
  data() {
    return {
      products: [],
      categories: [],
      covers: [],
      services: [],
      loading: false
    };
  },
  created() {
    this.$store.dispatch('insertCount');
    this.fetch();
    this.fetchCover();
    this.fetchHomeTexts();
  },
  methods: {
    fetch() {
      this.loading = true;

      Bluebird.resolve(CategoriesRepository.findAll())
        .then(({ data: categories }) =>
          Bluebird.map(categories, this.fetchCategoryDetails, {
            concurrency: 1
          })
        )
        .then(categoriesMounted => {
          this.categories = categoriesMounted;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCategoryDetails(category) {
      return Bluebird.resolve(
        CategoriesRepository.findCategoryPicture(category.id)
      ).then(({ data }) => ({
        pictures: data,
        ...category
      }));
    },
    fetchCover() {
      ContentRepository.cover().then(({ data: covers }) => {
        this.covers = covers;
      });
    },
    fetchHomeTexts() {
      Bluebird.resolve(ContentRepository.findHomeTexts())
        .then(({ data: services }) =>
          Bluebird.map(services, this.fetchServicesImgs, { concurrency: 1 })
        )
        .then(servicesMounted => {
          this.services = servicesMounted;
        });
    },
    fetchServicesImgs(content) {
      return ContentRepository.findServicesImgs().then(
        ({ data: servicesImgs }) => ({
          picture: servicesImgs.find(
            serviceImg => serviceImg.id_dom_obj === content.id_dom_obj
          ).bytesarq_base64,
          ...content
        })
      );
    }
  }
};
</script>
<style>
.product-container {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
</style>
