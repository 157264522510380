<template>
  <div id="app">
    <mobile-menu :items="items" :title="title" v-if="mobile" />

    <desktop-menu :items="items" :title="title" v-else />
    <v-fade-transition mode="out-in">
      <router-view :key="$route.fullPath"></router-view>
    </v-fade-transition>

    <div class="progress-container">
      <nprogress-container></nprogress-container>
    </div>
  </div>
</template>
<style>
.v-application--wrap {
  min-height: 10vh !important;
}
</style>
<script>
import MobileMenu from "./components/mobile/mobile-menu.vue";
import DesktopMenu from "./components/desktop/desktop-menu.vue";
import NprogressContainer from "vue-nprogress/src/NprogressContainer";

export default {
  name: "app",
  data: () => ({
    mobile: false,
    items: [
      {
        icon: "mdi-home",
        title: "Inicio",
        to: "/"
      },
      {
        icon: "mdi-office-building",
        title: "Empresa",
        to: "/sobre"
      },
      {
        icon: "mdi-radio-tower",
        title: "Produtos",
        to: "/",
        subLinks: true
      },
      {
        icon: "mdi-image",
        title: "Clientes",
        to: "/clientes"
      },
      {
        icon: "mdi-mail",
        title: "Contato",
        to: "/contato"
      },
    ],
    title: "Polidesign"
  }),
  components: {
    DesktopMenu,
    MobileMenu,
    NprogressContainer
  },
  mounted() {
    this.$store.dispatch('insertCategories');
    this.isMobile();
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.mobile = !this.mobile;
      }
    },
  }
};
</script>
<style>
.v-application--wrap {
  min-height: 6vh !important;
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
</style>
