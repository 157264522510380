import axios from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";

export default axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  auth: {
    user: process.env.VUE_APP_AUTH_USER,
    password: process.env.VUE_APP_AUTH_PASSWORD
  },
  rejectUnauthorized: false,
  adapter: cacheAdapterEnhancer(axios.defaults.adapter)
});
