import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/sobre",
    name: "about",
    component: () => import(/* webpackChunkName: "about" */ "@/views/About.vue")
  },
  {
    path: "/contato",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "@/views/Contact.vue")
  },
  {
    path: "/produtos",
    name: "products",
    props: true,
    component: () =>
      import(/* webpackChunkName: "product" */ "@/views/Products.vue")
  },
  {
    path: "/categorias",
    name: "categories",
    component: () =>
      import(/* webpackChunkName: "product" */ "@/views/Categories.vue")
  },
  {
    path: "/clientes",
    name: "clients",
    component: () =>
      import(/* webpackChunkName: "product" */ "@/views/Clients.vue")
  },
  {
    path: "*",
    name: "home",
    component: Home
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
