<template>
   <v-expansion-panels flat>
    <v-expansion-panel style="background:#3f51b5;color:white;width:100%">
      <v-expansion-panel-header>
        <v-icon style="margin-left: -2.2em;">{{ icon }}</v-icon> 
        <span> {{ title }} </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
         <v-list dark color="indigo">
          <v-list-item
            v-for="(category, i) in categories"
            :key="i"
            @click="detail(category.id)"
            exact
            color="white"
          >
            <v-icon style="margin-right: 8px">mdi-shape-outline</v-icon> 
            <v-list-item-title v-text="category.nome"/>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  props: {
    title: {
      type: String
    },
    icon: {
      type: String
    },
    categories: {
      type: Array
    }
  },
  methods: {
    detail(id) {
      this.$router.replace({ name: "products", query: { categoria: id } });
    }
  },  
};
</script>