import Repository from "./repository";

const resource = "/Categorias";

export default {
  findAll() {
    return Repository.get(`${resource}`);
  },

  findCategoryPicture(idCategory) {
    return Repository.get(`${resource}/Imagem?idCategoria=${idCategory}`);
  }
};
