<template>
  <v-carousel
    eager
    :show-arrows="false"
    :height="`${height}px`"
    aspect-ratio="1"
    cycle
    interval="5000"
  >
    <v-carousel-item
      v-for="(picture, i) in pictures"
      :key="i"
      :src="mountPicture(picture, keyToPicture)"
      fade
    />
  </v-carousel>
</template>
<script>
export default {
  props: {
    height: {
      type: Number,
      default: 450
    },
    width: {
      type: Number,
      default: 450
    },
    pictures: {
      type: Array,
      required: false
    },
    keyToPicture: {
      type: String,
      required: false,
      default: "BytesArq_base64"
    }
  },
  data() {
    return {
      localPicture: null
    };
  },
  methods: {
    mountPicture(picture, keyToPicture) {
      return `data:image/jpeg;base64,${picture[keyToPicture]}`;
    }
  }
};
</script>
