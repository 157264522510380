import Repository from "./repository";

export default {
  findHomeTexts() {
    return Repository.get("/Home/Textos");
  },
  findTextsByScreen(idScreen) {
    return Repository.get(`/Textos/Pagina?idTela=${idScreen}`);
  },
  cover() {
    return Repository.get("/Home/Imagens/Capa");
  },
  findContacts() {
    return Repository.get("/Contatos");
  },
  findServicesImgs() {
    return Repository.get("/Servicos");
  },
  sendEmail(data) {
    return Repository.post("/Contatos/Email", data);
  },
  findClients() {
    return Repository.get("/Clientes")
  },
  downloadPDF(clientId) {
    return Repository.get(`/Clientes/${clientId}`)
  }
};
